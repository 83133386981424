<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="8" :offset="8">
        <el-form ref="ruleForm" label-position="right" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm">
          <el-form-item :class="$i18n.locale" label="仓库名称" prop="logicWarehouseCode">
            <el-select v-model="ruleForm.logicWarehouseCode" clearable filterable placeholder="请选择" style="width:100%;">
              <el-option
                v-for="item in warehouseList"
                :key="item.logicWarehouseCode"
                :value="item.logicWarehouseCode"
                :label="item.logicWarehouseName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="年月" prop="inventoryPeriod">
            <el-date-picker
              v-model="ruleForm.inventoryPeriod"
              type="date"
              value-format="yyyy-MM"
            />
          </el-form-item>
          <el-form-item label="是否删除历史数据" prop="oldData">
            <el-radio-group v-model="ruleForm.oldData">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item ref="file" label="附件" prop="file">
            <el-upload
              ref="uploadForm"
              action=""
              :http-request="uploadFile"
              :limit="1"
              :on-remove="remove"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="fileDateChange"
            >
              <el-button type="primary">导入文件</el-button>
              <el-button type="text" @click="downTemplate('MANUAL_PROFIT_AND_LOSS')">模板下载</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="4" :offset="10">
        <el-button type="primary" class="mt-4" :loading="checkLoading" @click="save">提交</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { findDownloadUrl, manualProfitAndLoss } from '@/api/stock-out'
import { getWarehouse } from '@/api/stockin'
import { downloads } from '@/utils'

export default {
  data() {
    return {
      checkLoading: false,
      ruleForm: {
        logicWarehouseCode: '',
        inventoryPeriod: '',
        file: '',
        oldData: true
      },
      fileList: [],
      file: '',
      warehouseList: [],
      rules: {
        inventoryPeriod: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        oldData: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        logicWarehouseCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        warehouseCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        file: { required: true, message: '必填', trigger: 'change' }
      }
    }
  },
  computed: {
    saveParams() {
      const formDataParams = new FormData()
      const { cargoOwnerCode, channelCode, logicWarehouseName, sourceWarehouseCode, sourceWarehouseName, tenantCode, channelName } = this.warehouseList.find(item => item.logicWarehouseCode === this.ruleForm.logicWarehouseCode)
      const params = Object.assign({}, { cargoOwnerCode, channelCode, logicWarehouseName, sourceWarehouseCode, sourceWarehouseName, tenantCode, channelName }, this.ruleForm)
      console.log(params)
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    }
  },
  mounted() {
    this._queryWarehouseList()
  },
  methods: {
    async downTemplate(key) {
      const { datas } = await findDownloadUrl(key)
      downloads(datas)
    },
    save() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.$refs.uploadForm.submit()
        }
      })
    },
    fileDateChange(file, fileList) {
      this.fileList = fileList
      this.ruleForm.file = file.raw
      console.log(this.ruleForm.file)
      this.$refs.file.clearValidate()
    },
    remove() {
      this.ruleForm.file = ''
      this.fileList = []
    },
    async uploadFile() {
      try {
        this.checkLoading = true
        const { code, msg } = await manualProfitAndLoss(this.saveParams)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          Object.assign(this.ruleForm, this.$options.data.call(this).ruleForm)
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.checkLoading = false
        this.ruleForm.file = ''
        this.fileList = []
      }
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    }
  }
}
</script>

<style>

</style>
